// import { createContext, useState } from "react";

// export const UserContext = createContext({
//   currentUser: null,
//   DNDEnabled: false,
//   setDNDEnabled: () => null,
//   setCurrentUser: () => null,
// });

// export const UserProvider = ({ children }) => {
//   const [currentUser, setCurrentUser] = useState(null);
//   const [DNDEnabled, setDNDEnabled] = useState(false);
//   const value = { currentUser, DNDEnabled, setDNDEnabled, setCurrentUser };
//   return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
// };

import React, { createContext, useState, useEffect } from "react";
import { saveState, loadState } from "../utils/storageUtil"; // Adjust the import path as needed

export const UserContext = createContext({
  currentUser: null,
  DNDEnabled: false,
  setDNDEnabled: () => {},
  setCurrentUser: () => {},
});

export const UserProvider = ({ children }) => {
  // Initialize state with values from localStorage if available
  const [currentUser, setCurrentUser] = useState(
    () => loadState("currentUser") || null
  );
  const [DNDEnabled, setDNDEnabled] = useState(
    () => loadState("DNDEnabled") || false
  );

  // Save state to localStorage whenever it changes
  useEffect(() => {
    saveState("currentUser", currentUser);
  }, [currentUser]);

  useEffect(() => {
    saveState("DNDEnabled", DNDEnabled);
  }, [DNDEnabled]);

  const value = { currentUser, DNDEnabled, setDNDEnabled, setCurrentUser };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
