import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Popover,
  List,
} from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { UserContext } from "../../contexts/user.context";
import "./current-call-display.styles.scss";

const CurrentCallDisplay = ({ isOpen, callerName, callerID }) => {
  const { currentUser } = useContext(UserContext);
  const [callTime, setCallTime] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const isVPBX =
    currentUser?.account.account_id === "2539607fdce8159bf245e9479ec90f71"
      ? true
      : false;

  const call_labels = {
    cancelation: {
      value: "Billing: Cancelation",
      url: `https://webhook.na.myreports.cloud/tags/897ba0e5-ed0e-450e-a6c6-36ab9463e103?value=cancelation&presenceId=${currentUser?.presence_id}`,
    },
    collection: {
      value: "Billing: Collection",
      url: `https://webhook.na.myreports.cloud/tags/897ba0e5-ed0e-450e-a6c6-36ab9463e103?value=collection&presenceId=${currentUser?.presence_id}`,
    },
    escalated: {
      value: "CX: Escalated",
      url: `https://webhook.na.myreports.cloud/tags/292219b3-d2c2-4805-8960-983837a3c89d?value=Escalated&presenceId=${currentUser?.presence_id}`,
    },
    fcr: {
      value: "CX: FCR",
      url: `https://webhook.na.myreports.cloud/tags/292219b3-d2c2-4805-8960-983837a3c89d?value=FCR&presenceId=${currentUser?.presence_id}`,
    },
    follow_up: {
      value: "CX: Follow up",
      url: `https://webhook.na.myreports.cloud/tags/292219b3-d2c2-4805-8960-983837a3c89d?value=Follow%20Up&presenceId=${currentUser?.presence_id}`,
    },
    airDial_lead: {
      value: "Sales: AirDial Lead",
      url: `https://webhook.na.myreports.cloud/tags/759517c0-654a-4acb-8c40-c40f9b0528ac?value=AirDial%20Lead&presenceId=${currentUser?.presence_id}`,
    },
    business_phone_lead: {
      value: "Sales: Business Phone Lead",
      url: `https://webhook.na.myreports.cloud/tags/759517c0-654a-4acb-8c40-c40f9b0528ac?value=Business%20Phone%20Lead&presenceId=${currentUser?.presence_id}`,
    },
    contact_center_lead: {
      value: "Sales: Contact Center Lead",
      url: `https://webhook.na.myreports.cloud/tags/759517c0-654a-4acb-8c40-c40f9b0528ac?value=Contact%20Center%20Lead&presenceId=${currentUser?.presence_id}`,
    },
    at_risk: {
      value: "At Risk",
      url: `https://webhook.na.myreports.cloud/tags/b246188b-58f3-4055-94b1-1c7b69402d2a?value=At%20Risk&presenceId=${currentUser?.presence_id}`,
    },
  };

  useEffect(() => {
    let interval;
    if (isOpen) {
      interval = setInterval(() => {
        setCallTime((prevTime) => prevTime + 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isOpen]);

  const formatTime = (seconds) => {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return `${min}:${sec < 10 ? "0" : ""}${sec}`;
  };

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLabelClick = (url) => {
    try {
      // console.log(url);
      fetch(url, { mode: "no-cors" })
        .then((response) => "")
        // .then(data => console.log(data))
        .finally(() => handleClose());
    } catch (error) {
      console.log(error);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box className="on-call-display-container">
      {isVPBX ? (
        <Box className="label-icon-container">
          <IconButton size="small" onClick={handleIconClick}>
            <LocalOfferIcon />
          </IconButton>
        </Box>
      ) : (
        ""
      )}
      <Typography variant="h5">Current call</Typography>
      <Typography>Duration: {formatTime(callTime)}</Typography>
      <Typography>{callerName}</Typography>
      {/* <Typography>{callerID}</Typography> */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="popover"
      >
        <List>
          {Object.entries(call_labels).map(([key, { value, url }]) => (
            <Button
              className="button-label"
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                // textTransform: "none",
                color: "black",
                width: "auto",
                margin: "10px",
              }}
              key={key}
              onClick={() => handleLabelClick(url)}
              fullWidth
            >
              {value}
            </Button>
          ))}
        </List>
      </Popover>
    </Box>
  );
};

export default CurrentCallDisplay;
