import { useState, useContext } from "react";
import {
  Avatar,
  Box,
  Typography,
  // Popover,
  MenuItem,
  Menu,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { UserContext } from "../../contexts/user.context";
import UILoading from "../ui-loading/ui-loading.component.jsx";
import { KazooAPI as api } from "../../utils/kazoo.js";
import "./avatar.styles.scss";

function UserProfile() {
  const { currentUser, DNDEnabled, setDNDEnabled, setCurrentUser } =
    useContext(UserContext);
  // const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState(
    DNDEnabled ? "Do not disturb" : "Available"
  );
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleStatusClick = (event) => {
    setStatusAnchorEl(event.currentTarget);
  };

  const handleStatusClose = () => {
    setStatusAnchorEl(null);
  };

  const handleStatusChange = async (event) => {
    // setStatus(event.target.innerText);
    handleStatusClose();
    setIsLoading(true);
    try {
      if (event.target.innerText === "Do not disturb") {
        //SET DND to true
        const newToken = await api.refreshToken(
          currentUser.auth_hash,
          currentUser.account.account_name
        );
        setCurrentUser((currentUser) => ({
          ...currentUser,
          auth_token: newToken,
        }));

        const DND_Status = await api.setDNDStatus(
          currentUser.user_id,
          currentUser.account.account_id,
          true,
          newToken
        );
        console.log(DND_Status);
        setDNDEnabled(true);
        setStatus("Do not disturb");
      } else {
        //SET DND to false
        const newToken = await api.refreshToken(
          currentUser.auth_hash,
          currentUser.account.account_name
        );
        setCurrentUser((currentUser) => ({
          ...currentUser,
          auth_token: newToken,
        }));

        const DND_Status = await api.setDNDStatus(
          currentUser.user_id,
          currentUser.account.account_id,
          false,
          newToken
        );
        console.log(DND_Status);
        setDNDEnabled(false);
        setStatus("Available");
      }
      // handleStatusClose();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;

  const statusOpen = Boolean(statusAnchorEl);
  const statusId = statusOpen ? "status-menu" : undefined;

  const statusColors = {
    Available: "#56F000",
    "Do not disturb": "#FF3838",
  };

  const userInitials = currentUser
    ? `${currentUser.first_name.charAt(0)}${currentUser.last_name.charAt(0)}`
    : "";

  return (
    <Box className="user-profile-container">
      {isLoading && <UILoading />}
      <Box className="avatar-box">
        <Avatar
          // alt="Remy Sharp"
          // src="/static/images/avatar/1.jpg"
          // onClick={handleClick}
          className="avatar"
        >
          {" "}
          {userInitials}
        </Avatar>
        <Box
          className="status-dot"
          sx={{ backgroundColor: statusColors[status] }}
        />
      </Box>
      <Box>
        <Typography variant="body1" className="username">
          {currentUser
            ? `${currentUser.first_name} ${currentUser.last_name}`
            : ""}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="caption"
            sx={{ color: "black", cursor: "pointer" }}
            onClick={handleStatusClick}
          >
            {status}
          </Typography>
          <ArrowDropDownIcon
            sx={{ ml: 1, cursor: "pointer" }}
            onClick={handleStatusClick}
          />
          <Menu
            id={statusId}
            anchorEl={statusAnchorEl}
            open={statusOpen}
            onClose={handleStatusClose}
            MenuListProps={{
              "aria-labelledby": "status-button",
            }}
          >
            <MenuItem onClick={handleStatusChange}>Available</MenuItem>
            <MenuItem onClick={handleStatusChange}>Do not disturb</MenuItem>
          </Menu>
        </Box>
      </Box>
      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      ></Popover> */}
    </Box>
  );
}

export default UserProfile;
